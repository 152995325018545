@import "styles/libraries/bootstrap";

@import "styles/variables-custom-properties";

body {
  @extend .bg-dark;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: $secondary;
  font-family: "Gruppo", cursive;
  text-align: center;

  a {
    color: inherit;
    text-decoration: none;

    &:active,
    &:visited {
      color: inherit;
    }
  }
}

// components %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// atoms -----------------------------------------------------------------------
@import "styles/components/atoms/social-my";

// molecules -------------------------------------------------------------------

// organisms -------------------------------------------------------------------

// templates -------------------------------------------------------------------

// pages -----------------------------------------------------------------------
@import "styles/components/pages/home";
