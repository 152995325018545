.navigation-links {
  padding: 30px;

  a {
    color: var(--bs-secondary);
    font-weight: bold;

    &.router-link-exact-active {
      color: var(--bs-green);
    }
  }
}
