.social-my {
  padding: 24px;
  text-align: center;

  .social-links {
    word-break: break-all;
    word-spacing: 5px;

    a {
      &:hover {
        color: rgb(86, 111, 131);
        text-decoration: underline;
      }
    }
  }
}
